import { CombinedError } from "@urql/core";
import { type ErrorContextValue } from "@wpf-admin-ui/contexts/ErrorContext";
import { useError } from "@wpf-admin-ui/hooks/useError";
import { useCallback, useEffect, useMemo } from "react";

type HandleErrorFn = (error: unknown, option?: { fallbackMessage?: string }) => void;

export const useGraphQLError = (
  ...errors: unknown[]
): ErrorContextValue & {
  handleError: HandleErrorFn;
} => {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- エラー内容に応じてメモ化する
  const memoizedErrors = useMemo(() => errors.filter(Boolean), [...errors]);

  const context = useError();
  const { handleError: handleErrorInContext } = context;

  const handleError: HandleErrorFn = useCallback(
    (error, option = {}): void => {
      if (error instanceof CombinedError) {
        if (error.networkError) {
          handleErrorInContext({ message: "ネットワーク回線を確認してください。" });
          return;
        }
        // バックエンドでUI向けにメッセージを入れている場合はそれを表示する
        if (error.graphQLErrors.length > 0 && error.graphQLErrors[0].extensions.message) {
          handleErrorInContext({ message: error.graphQLErrors[0].extensions.message });
          return;
        }
      }

      handleErrorInContext({
        message: option.fallbackMessage ?? "エラーが発生しました。しばらくしてから再度お試しください。",
      });
    },
    [handleErrorInContext],
  );

  useEffect(() => {
    memoizedErrors.forEach((error) => {
      if (error) {
        handleError(error);
      }
    });
  }, [memoizedErrors, handleError]);

  return { ...context, handleError };
};
